<template>
    <div class="bg re_sizing re_relative">
        <div class="title re_relative re_flex_center re_font_18 re_color_normal" :class="{ 'is-pad-top': userStore.isPadTop }">
            <van-icon @click="goBack" class="icon" name="arrow-left" size="22" />
            <span v-if="showType == 1">溜溜积分商城</span>
            <span v-if="showType == 2">兑换记录</span>
            <span v-if="showType == 1" class="re_font_14 re_absolute re_margin_l_8 explain re_color_light" @click="showType = 2">兑换记录</span>
        </div>
        <div v-if="showType == 1" class="padtop re_sizing re_text_c">
            <img src="https://tp.kaishuihu.com/dsgc/icon/static/noShop.png" alt="">
            <div class="re_font_14 re_color_light">商城搭建中...</div>
        </div>
        <div v-else class="padtop re_sizing re_text_c">
            <img src="https://tp.kaishuihu.com/dsgc/icon/static/noRecord.png" alt="">
            <div class="re_font_14 re_color_light">暂无记录</div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue';
import { router } from '../../router';
import { useUserStore } from '../../stores/user';
const userStore = useUserStore();
const showType = ref(1)
const goBack = () => {
    if(showType.value == 2) showType.value = 1
    else router.back()
}
onMounted(() => {
    
})


</script>

<style lang="less" scoped>
.bg {
    width: 100%;
    height: 100vh;
    background-color: #F5F7F9;
    z-index: 100;
    .padtop{
        padding-top: 80px;
    }
    .title {
        padding: 30px 26px 18px 26px;
        width: calc(100% - 52px);
        font-weight: 600;
        background-color: #fff;

        .icon {
            position: absolute;
            left: 26px;
            bottom: 18px;
        }

        .explain {
            right: 26px;
            bottom: 18px;
        }
    }
    .is-pad-top {
        padding-top: 46px;
    }
    img{
        width: 291px;
        height: 164px;
    }
    
}

</style>